import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import dayjs from 'dayjs';

import styled from '@emotion/styled';
import { formatBytes } from 'utils/formatting';
import Button from 'components/Button';
import Icon, { IconName } from 'components/Icon';
import PopMenu from 'components/PopMenu';

interface TreeNodeProps extends Omit<React.HTMLAttributes<HTMLTableRowElement>, 'onClick'> {
  data: AnyNode;
  onClick?: (event: React.MouseEvent<HTMLTableRowElement>, data: AnyNode) => void;
  onDeleteClick?: (data: AnyNode) => void;
  onShareClick?: (data: DocumentNode) => void;
}

const TreeNode: React.FC<TreeNodeProps> = ({
  data,
  onClick,
  onDeleteClick,
  onShareClick,
  className,
  ...props
}) => {
  const menuButtonRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const getIcon = (type: typeof data.type): IconName => {
    switch (type) {
      case 'folder':
        return 'document-folder';
      case 'document':
        if ((data as DocumentNode).mimeType.includes('image')) {
          return 'document-image';
        }
        return 'document-file';
      default:
        return 'document-file';
    }
  };

  const handleClick = e => {
    if (onClick) onClick(e, data);
  };

  const handleMenuClick = e => {
    e.stopPropagation();
    setMenuOpen(v => !v);
  };

  const handleDeleteClick = e => {
    e.stopPropagation();
    setMenuOpen(false);
    if (onDeleteClick) onDeleteClick(data);
  };

  const handleShareClick = e => {
    e.stopPropagation();
    setMenuOpen(false);
    if (onShareClick) onShareClick(data as DocumentNode);
  };

  const handleEdit = e => {
    e.stopPropagation();
    setMenuOpen(false);
    window.open(`/pdf-editor?id=${data.id}`)
  };


  

  return (
    <StyledTreeNode
      className={clsx('tree-node', data.type, className)}
      onClick={handleClick}
      {...props}>
      <td className="tree-node__name">
        <Icon name={getIcon(data.type)} />
        {data.name}
      </td>
      <td className="tree-node__date" width={200}>
        {dayjs(data.createdOn).format('MMM DD, YYYY, HH:mm A')}
      </td>
      <td className="tree-node__type">{data.displayType}</td>
      <td className="tree-node__size">{data.type === 'document' && formatBytes(data.size)}</td>
      <td className="tree-node__menu">
        <Button ref={menuButtonRef} onClick={handleMenuClick} simple>
          <Icon name="menu-dots" />
        </Button>
        {createPortal(
          <StyledTreeNodeMenu
            target={menuButtonRef.current}
            open={menuOpen}
            placement="bottom-end"
            onOutsideClick={() => setMenuOpen(false)}
            outsideClickNoTarget>
            {data.type === 'document' && (
              <Button simple onClick={handleShareClick}>
                Share
              </Button>
            )}
            <Button simple onClick={handleDeleteClick}>
              Delete
            </Button>
            <Button simple onClick={handleEdit}>
              eSignatures
            </Button>
          </StyledTreeNodeMenu>,
          document.querySelector('#modal-root')!
        )}
      </td>
    </StyledTreeNode>
  );
};

export default TreeNode;

const StyledTreeNode = styled.tr`
  cursor: pointer;

  td {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    height: 32px;
  }
  &:not(:last-of-type) td {
    border-bottom: 1px solid ${props => props.theme.colors.seashell};
  }

  .icon {
    vertical-align: middle;
  }
  &.folder .icon {
    fill: ${props => props.theme.colors.grayDark};
  }
  .tree-node__name .icon {
    margin: 0 8px 2px 0;
  }
  .tree-node__date,
  .tree-node__type,
  .tree-node__size {
    color: ${props => props.theme.colors.grayDark};
  }
  .tree-node__menu {
    width: 32px;
    text-align: center;
  }

  &:hover {
    background: ${props => props.theme.colors.seashell};
  }
`;

const StyledTreeNodeMenu = styled(PopMenu)`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 8px 12px;
  width: 160px;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: ${props => props.theme.misc.borderRadius};

  .button {
    justify-content: left;
    margin: 8px 0;
  }
`;
